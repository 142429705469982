import { List } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import _ from "lodash";
import { NAME_AUTOTICK_SELECT } from "Utils/data-default";
import { mergeTwoArray } from "Utils/convertValue";

const ListWrapper = styled(List)`
  padding-bottom: 2rem;

  .ant-list {
    padding: 0 0 !important;
  }

  .ant-list-item {
    background-color: #dcefff ;
    border: 1px solid #c9c9c9;
    border-block-end: none;
    padding: 0 0 !important ;
    min-height: 100%;
    display: flex;
    align-items: inherit;
    &:last-child{
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      overflow: hidden;
    }
    .list-item-subject{
      width: 100%;
      background-color: #fafafa;
      border-left: 1px solid rgb(201, 201, 201);
      .ant-list-items{
        .ant-list-item{
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #c9c9c9 !important;
          &:last-child{
            border-bottom: none !important;
          }
          .list-action{
            border-left: none;
           
            &.greater-than-5 , &.greater-than-6, &.greater-than-7{
                min-height: 104px ;
            }
            @media only screen and (max-width: 1400px) {
              &.greater-than-4 {
                min-height: 104px !important;
              }
            }
            @media only screen and (max-width: 1200px) {
              &.greater-than-5 , &.greater-than-6, &.greater-than-7{
                min-height: 156px ;
              }
            }
            @media only screen and (max-width: 992px) {
              &.greater-than-5 , &.greater-than-6, &.greater-than-7{
                min-height: 208px !important;
              }
              &.greater-than-4 {
                min-height: 156px !important;
              }
              &.greater-than-2, &.greater-than-3{
                min-height: 104px !important;
              }
            }
            @media only screen and (max-width: 768px) {
              &.greater-than-5 , &.greater-than-6, &.greater-than-7{
                min-height: 260px !important;
              }
              
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1300px) {
      height: 100% !important;
    }

    @media only screen and (max-width: 576px) {
      height: 100% !important;
    }
    
  }

  .ant-list-item:last-child {
    border-bottom: 1px solid #c9c9c9;
  }

  .ant-list .ant-list-item {
    padding: 0 0 !important;
  }

  .name-courses {
    min-width: 208px !important;
    /* height: 100% !important; */
    display: flex;
    justify-content: start;
    /* padding-left: 50px; */
    align-items: center;
    white-space: nowrap;
    box-sizing: border-box;
    @media only screen and (max-width: 1300px) {
      height: auto !important;
    }

    /* @media only screen and (max-width: 576px) {
      height: 100% !important;
    } */
    span {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 30px;
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
  .child-subject{
    width: 50%;
    height: 100%;

    .key-child{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      font-weight: 600;
      font-size: 1.3rem;
      border-left: 1px solid #c9c9c9;
      border-bottom: 1px solid #c9c9c9;
      &:last-child{
        border-bottom: 0px;
      }
      span{
        line-height: 52px;
        align-items: center;
          &.greater-than-5 , &.greater-than-6, &.greater-than-7{
          min-height: 104px !important;
        }
        @media only screen and (max-width: 1400px) {
          &.greater-than-4 {
            min-height: 104px !important;
          }
        }
        @media only screen and (max-width: 1200px) {
          &.greater-than-5 , &.greater-than-6, &.greater-than-7{
            min-height: 156px !important;
          }
        }
        @media only screen and (max-width: 992px) {
          &.greater-than-5 , &.greater-than-6, &.greater-than-7{
            min-height: 208px !important;
          }
          &.greater-than-4 {
            min-height: 156px !important;
          }
          &.greater-than-2, &.greater-than-3{
            min-height: 104px !important;
          }
        }
        @media only screen and (max-width: 768px) {
          &.greater-than-5 , &.greater-than-6, &.greater-than-7{
            min-height: 260px !important;
          }

        }
      }
    }
  }
  
  .list-action {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 52px;
    border-left: 1px solid #c9c9c9;
    background-color: #fafafa ;

    .course-item {
      padding-right: 10px;
      .empty{
        visibility: hidden;
      }

      @media only screen and (max-width: 576px) {
      padding-right: 5px;
    }
      /* display: flex;
      align-items: center; */
    }

    input[type="checkbox"] {
      appearance: none;
      background-color: #fff;
      border-radius: 2px;
      display: inline-grid;
      place-content: center;
      margin-right: 10px;
      width: 28px;
      height: 28px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 20px;
      height: 20px;
      transform: scale(0);
      transform-origin: bottom left;
      background-color: #000;
      clip-path: polygon(39% 82%, 90% 15%, 97% 21%, 40% 96%, 0 65%, 6% 57%);
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
    input[type="checkbox"]:checked {
      background-color: #fff;
      border: 2px solid #ccc;
    }
  }
`;

const Title = styled(List)`
  padding-top: 2rem;
  margin-top: 16px;

  .ant-list {
    padding: 0 0 !important;
  }

  .ant-list-item {
    background-color: #dcefff ;
    border: 1px solid #c9c9c9;
    border-block-end: none;
    padding: 0 0 !important ;
    min-height: 100% !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @media only screen and (max-width: 1300px) {
      height: 100% !important;
    }

    @media only screen and (max-width: 576px) {
      height: 100% !important;
    }
  }

  .ant-list .ant-list-item {
    padding: 0 0 !important;
  }

  .name-courses {
    min-width: 208px !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    box-sizing: border-box;
    @media only screen and (max-width: 1300px) {
      height: 100% !important;
    }

    @media only screen and (max-width: 576px) {
      height: 100% !important;
    }
    span {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 30px;
    }
  }

  .list-action {
    width: 100%;
    padding-left: 1rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 52px;
    border-left: 1px solid #c9c9c9;
    text-align: center;

    .course-item {
      padding-right: 10px;
      font-weight: bold;
      @media only screen and (max-width: 576px) {
      padding-right: 5px;
    }
      /* display: flex;
      align-items: center; */
    }

    input[type="checkbox"] {
      appearance: none;
      background-color: #fff;
      border-radius: 2px;
      display: inline-grid;
      place-content: center;
      margin-right: 10px;
      width: 28px;
      height: 28px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 20px;
      height: 20px;
      transform: scale(0);
      transform-origin: bottom left;
      background-color: #000;
      clip-path: polygon(39% 82%, 90% 15%, 97% 21%, 40% 96%, 0 65%, 6% 57%);
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
    input[type="checkbox"]:checked {
      background-color: #fff;
      border: 2px solid #ccc;
    }
  }
`;

function ListCourseChecked({ loading, dataSource, dataselectedContentPerson }) {
  const [t] = useTranslation('student');
  let title = [{
    name: "",
    itemChecked: "共通テスト",
    itemCheckedPerson: "二次・個別"
  }]
  
  const listCourseChecked = useMemo(() => {
    let arr = []
    if(!_.isEmpty(dataSource)){
      for (const [key, value] of Object.entries(dataSource)) {
        arr.push({
          name: key,
          itemChecked: value
        }) 
      }
    }
    if (!_.isEmpty(dataselectedContentPerson)) {
      for (const [key, value] of Object.entries(dataselectedContentPerson)) {
        if (arr.every(item => item.name !== key)) {
          arr.push({
            name: key,
            itemCheckedPerson: value
          }) 
        }
        arr.forEach(data => {
          if (data?.name === key) {
            Object.assign(data, { itemCheckedPerson: value });
          }
        });
      }
    }
    return arr
  }, [dataSource, dataselectedContentPerson])
  const renderClassChecked = (item) => {
    const lengthItem = item?.length
    if (lengthItem >= 7) {
      return 'greater-than-7'
    }
    if (lengthItem >= 6) {
      return 'greater-than-6'
    }
    else if (lengthItem >= 5) {
      return 'greater-than-5'
    }
    else if (lengthItem >= 4) {
      return 'greater-than-4'
    }
    else if (lengthItem >= 3){
      return 'greater-than-3'
    }
    else return 'greater-than-2'
  }
  return (
    <>
      <Title
        // loading={loading}
        itemLayout="horizontal"
        dataSource={title}
        renderItem={(item) => (
          <List.Item>
            <div className="name-courses">
              <span>{item?.name}</span>
            </div>
            <div className="list-action">
              <label  className="course-item">
                {item?.itemChecked} 
              </label>
            </div>
            <div className="list-action">
              <label  className="course-item">
                {item?.itemCheckedPerson}
              </label>
            </div>
          </List.Item>
        )}
      />
      <ListWrapper
        loading={loading}
        itemLayout="horizontal"
        dataSource={listCourseChecked}
        locale={{ emptyText:t("no_data")}}
        renderItem={(item) => {
          const uniqueKeyChecked = item?.itemChecked ? Object.keys(item?.itemChecked).filter(key => item.itemChecked[key].length > 0) : []
          const uniqueKeCheckedPerson = item?.itemCheckedPerson ? Object.keys(item?.itemCheckedPerson).filter(key => item.itemCheckedPerson[key].length > 0) : []
          const mergeKeyItemChecked = mergeTwoArray(uniqueKeyChecked, uniqueKeCheckedPerson)
          console.log('item',item)
          return (
            <List.Item>
              <div className="name-courses">
                <span>{item?.name}</span>
                <div className="child-subject">
                {item?.name === NAME_AUTOTICK_SELECT.SOCIETY && (
                    <>
                      {mergeKeyItemChecked.map(key =>
                      {
                        const lengthChecked = item?.itemChecked?.[key]?.length || 0
                        const lengthCheckedPerson =  item?.itemCheckedPerson?.[key]?.length || 0
                        return (
                          <div className={`key-child `} key={key}>
                            <span className={` ${renderClassChecked((lengthChecked > lengthCheckedPerson) ? item?.itemChecked?.[key] : item?.itemCheckedPerson?.[key])}`}>{key}</span>
                          </div>
                        )
                      }
                      )}
                    </>
                  )}
                  {item?.name === NAME_AUTOTICK_SELECT.SCIENCE &&
                    mergeKeyItemChecked.map(key => {
                      const lengthChecked = item?.itemChecked?.[key]?.length || 0
                      const lengthCheckedPerson =  item?.itemCheckedPerson?.[key]?.length || 0
                      return (
                        <div className={`key-child `} key={key}>
                          <span className={` ${renderClassChecked((lengthChecked > lengthCheckedPerson) ? item?.itemChecked?.[key] : item?.itemCheckedPerson?.[key])}`}>{key}</span>
                        </div>
                      )
                    }
                    )}
                </div>
              </div>
              {(item.name !== NAME_AUTOTICK_SELECT.SCIENCE && item.name !== NAME_AUTOTICK_SELECT.SOCIETY) ?
                <>
                <div className="list-action">
                  <label className="course-item">
                    {item?.itemChecked &&  item?.itemChecked
                    .filter(value => isNaN(value))
                    .join("、")}
                  </label>
                </div>
                <div className="list-action">
                  <label className="course-item">
                      {item?.itemCheckedPerson && item?.itemCheckedPerson
                        .filter(value => isNaN(value))
                       .join("、")}
                  </label>
                </div>
                </>
                : 
                <>
                  <List
                    loading={loading}
                    itemLayout="horizontal"
                    dataSource={mergeKeyItemChecked}
                    className="list-item-subject"
                    locale={{ emptyText:' '}}
                    renderItem={(key) => {
                      const lengthChecked = item?.itemChecked?.[key]?.length || 0
                      const lengthCheckedPerson =  item?.itemCheckedPerson?.[key]?.length || 0
                      return (
                        <List.Item >
                          <div className={`list-action list-subject-content  ${renderClassChecked((lengthChecked > lengthCheckedPerson) ? item?.itemChecked?.[key] : item?.itemCheckedPerson?.[key])} `}>
                            <label className="course-item">
                              {(item?.itemChecked?.[key] && item?.itemChecked?.[key].length) ? item?.itemChecked?.[key].join(" 、") : <div className="empty">{key}</div>}
                            </label>
                          </div>
                        </List.Item>
                      )
                    }
                    }
                  />
                  <ListWrapper
                    loading={loading}
                    itemLayout="horizontal"
                    dataSource={mergeKeyItemChecked}
                    className="list-item-subject"
                    locale={{ emptyText:' '}}
                    renderItem={(key) => {
                      const lengthChecked = item?.itemChecked?.[key]?.length || 0
                      const lengthCheckedPerson =  item?.itemCheckedPerson?.[key]?.length || 0
                      return (
                        <List.Item >
                          <div className={`list-action list-subject-content ${renderClassChecked((lengthChecked > lengthCheckedPerson) ? item?.itemChecked?.[key] : item?.itemCheckedPerson?.[key])} `}>
                            <label className="course-item">
                              {(item?.itemCheckedPerson?.[key] && item?.itemCheckedPerson?.[key].length)  ? item?.itemCheckedPerson?.[key].join(" 、") : <div className="empty">{key}</div>} 
                            </label>
                          </div>
                        </List.Item>
                      )
                    }
                    }
                  />
                </>
                
              }
            </List.Item>
          )
        }
        }
      />
    </>
  );
}

export default ListCourseChecked;
